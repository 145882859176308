.wrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 40px 0;

	@media (min-width: 1280px) {
		gap: 56px;
		padding: 80px 0;
	}
}

.titleWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 1000px;
}

.list {
	display: flex;
	flex-direction: column;
	gap: 40px;

	@media (min-width: 1024px) {
		padding-left: 32px;
		border-left: 1px solid #121314;
	}
}

.bottomContent {
	display: flex;
	flex-direction: column;
	gap: 24px;

	margin: 0 auto;
	max-width: 1136px;

	@media (min-width: 1024px) {
		gap: 56px;
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	gap: 16px;
	text-align: center;
}

.image {
	object-fit: contain;
	width: 100%;
}
