.wrapper {
	display: flex;
	align-items: center;
	gap: 12px;
	p {
		margin: 0;
		padding-top: 3px;
		font-size: 14px;
		line-height: 20px;
		letter-spacing: 0.14px;
		text-transform: uppercase;
		color: white;
	}
}

.black {
	color: black;

	p {
		color: black;
	}

	svg path {
		fill: black;
	}
}

.divider {
	width: 1px;
	height: 100%;
	opacity: 0.36;
	background: #b5b5b5;
}
