/* Montserrat-400*/
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 400;
	src: local(""), url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
/* Montserrat-500*/
@font-face {
	font-family: "Montserrat";
	font-style: normal;
	font-weight: 500;
	src: local(""), url("../fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}