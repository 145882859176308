.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;

	padding: 40px 0;
	margin: 0 auto;
	gap: 24px;

	@media (min-width: 768px) {
		align-items: flex-start;
		padding: 80px 0;
	}
}

.content {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;

	@media (min-width: 768px) {
		flex-direction: row;
	}

	&Image {

		@media (min-width: 768px) {
			width: 50%;
			max-width: 552px;
		}
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 12px;
	text-align: center;

	@media (min-width: 768px) {
		text-align: start;
		align-items: flex-start;
		gap: 16px;
		padding-left: 32px;
		border-left: 1px solid #121314;
	}
}

.image {
	width: 100%;
	object-fit: contain;
}
