.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
	cursor: grab;

	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		gap: 30px;
		cursor: auto;
	}
}

.textWrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.image {
	object-fit: contain;
	width: 100%;
	max-width: 406px;
}
